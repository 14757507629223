import React, { useState, useRef } from 'react';
import Header from './Header';
import Footer from './Footer';
import Sidebar from './Sidebar';

const DashboardLayout = (props) => {

    return (
        <>  
            <div className='wrapper'>
                <Header/>
                <Sidebar/>
                {props.children}
                <Footer {...props}/>
            </div>
        </>
    )

}


export default DashboardLayout