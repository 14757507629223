import React, { useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  usersSettings,
  updateUserSettings,
  onErrorStopLoad,
} from "../../../redux/slices/web";

const Setting = () => {
  const dispatch = useDispatch();
  const [settingForm, setSettingForm] = useState("");
  console.log("settingForm", settingForm);
  // Prevent default scroll behavior
  const handleScroll = (e) => {
    e.target.blur();
    e.stopPropagation();
    setTimeout(() => {
      e.target.focus();
    }, 0);
  };

  //Create for change email or password.
  const handleChange = (e) => {
    e.stopPropagation();
    const { value, name } = e.target;
    setSettingForm({ ...settingForm, [name]: value.trim() });
  };

  // getting setting details
  const getAdminSetting = () => {
    dispatch(
      usersSettings({
        cb(res) {
          if (res.status === 200) {
            // console.log("res", res)
            setSettingForm({
              limitEmail: res.data.data.limit_max_email,
              limitMinEmail: res.data.data.limit_min_email,
              limitPassword: res.data.data.limit_max_password,
              limitMinPassword: res.data.data.limit_min_password,
              limitPhoneNumber: res.data.data.limit_max_phone_number,
              limitMinPhoneNumber: res.data.data.limit_min_phone_number,
              limitUsername: res.data.data.limit_max_username,
              limitMinUsername: res.data.data.limit_min_username,
              maxFailedAttempt: res.data.data.max_failed_attempt,
              lockoutPeriod: res.data.data.lockout_period,
              recoveryLinkExpire: res.data.data.recovery_link_expire,
              accountsid: res.data.data.account_sid,
              authToken: res.data.data.auth_token,
              serviceId: res.data.data.service_id,
              sgApiKey: res.data.data.sg_api_key,
            });
          }
        },
      })
    );
  };

  useEffect(() => {
    getAdminSetting();
  }, []);

  useEffect(() => {
    dispatch(onErrorStopLoad());
  }, [dispatch]);

  console.log(settingForm.limitPassword, '||', settingForm.limitMinPassword);
  // update setting
  const handleSubmit = (e) => {
    e.preventDefault();

    if (parseInt(settingForm.limitMinPassword) < 1) {
      toast.error("Min password must be greater than 0");
      return;
    }
    else if (parseInt(settingForm.limitPassword) <= parseInt(settingForm.limitMinPassword)) {
      toast.error("Max password must be greater than min password");
      return;
    }
    else if (parseInt(settingForm.limitMinEmail) < 1) {
      toast.error("Min email must be greater than 0");
      return;
    }
    else if (parseInt(settingForm.limitEmail) <= parseInt(settingForm.limitMinEmail)) {
      toast.error("Max email must be greater than min email");
      return;
    }
    else if (parseInt(settingForm.limitMinPhoneNumber) < 0) {
      toast.error("Min phone number must be greater than 4");
      return;
    }
    else if (parseInt(settingForm.limitPhoneNumber) <= parseInt(settingForm.limitMinPhoneNumber)) {
      toast.error("Max phone number must be greater than min phone number");
      return;
    }
    else if (parseInt(settingForm.limitMinUsername) < 1) {
      toast.error("Min username must be greater than 0");
      return;
    }
    else if (parseInt(settingForm.limitUsername) <= parseInt(settingForm.limitMinUsername)) {
      toast.error("Max username must be greater than min username");
      return;
    }
    else if (parseInt(settingForm.maxFailedAttempt) < 1) {
      toast.error("Maximum failed attempt must be greater than 0");
      return;
    }
    else if (parseInt(settingForm.lockoutPeriod) < 1) {
      toast.error("Lockout period must be greater than 0");
      return;
    }
    else if (parseInt(settingForm.recoveryLinkExpire) < 1) {
      toast.error("Recovery link expire must be greater than 0");
      return;
    }


    let params = {
      limit_max_password: parseInt(settingForm.limitPassword),
      limit_min_password: parseInt(settingForm.limitMinPassword),
      limit_max_email: parseInt(settingForm.limitEmail),
      limit_min_email: parseInt(settingForm.limitMinEmail),
      limit_max_phone_number: parseInt(settingForm.limitPhoneNumber),
      limit_min_phone_number: parseInt(settingForm.limitMinPhoneNumber),
      limit_max_username: parseInt(settingForm.limitUsername),
      limit_min_username: parseInt(settingForm.limitMinUsername),
      max_failed_attempt: parseInt(settingForm.maxFailedAttempt),
      lockout_period: parseInt(settingForm.lockoutPeriod),
      recovery_link_expire: parseInt(settingForm.recoveryLinkExpire),
      account_sid: settingForm.accountsid,
      auth_token: settingForm.authToken,
      service_id: settingForm.serviceId,
      sg_api_key: settingForm.sgApiKey,

    };

    // console.log("params", params)
    dispatch(
      updateUserSettings({
        ...params,
        cb(res) {
          if (res.status === 200) {
            getAdminSetting();
          }
        },
      })
    );
  };

  return (
    <>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0 headingMain">Setting</h1>
              </div>
            </div>
          </div>
        </div>
        {/* Main content */}
        <section className="content">
          <div className="container-fluid">
            <div className="commonBox_">
              <h3 className="subHeading_">Field Validation Limits</h3>
              <form
                className="customForm"
                onSubmit={(e) => { handleSubmit(e) }}
              >
                <div className="row">
                  <div className="col-md-6 mb-2">
                    <div className="form-group">
                      <label className="form-label">Minimum Password</label>
                      <div className="listinput_">
                        <input
                          type="number"
                          className="form-input"
                          placeholder="10"
                          value={settingForm?.limitMinPassword}
                          name="limitMinPassword"
                          onChange={(e) => handleChange(e)}
                          onWheel={handleScroll}

                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 mb-2">
                    <div className="form-group">
                      <label className="form-label">Maximum password</label>
                      <input
                        type="number"
                        className="form-input"
                        placeholder="10"
                        value={settingForm?.limitPassword}
                        name="limitPassword"
                        onChange={(e) => handleChange(e)}
                        onWheel={handleScroll}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 mb-2">
                    <div className="form-group">
                      <label className="form-label">Minimum email</label>
                      <div className="listinput_">
                        <input
                          type="number"
                          className="form-input"
                          placeholder="10"
                          value={settingForm?.limitMinEmail}
                          name="limitMinEmail"
                          onChange={(e) => handleChange(e)}
                          onWheel={handleScroll}

                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 mb-2">
                    <div className="form-group">
                      <label className="form-label">Maximum email </label>
                      <input
                        type="number"
                        className="form-input"
                        placeholder="10"
                        value={settingForm?.limitEmail}
                        name="limitEmail"
                        onChange={(e) => handleChange(e)}
                        onWheel={handleScroll}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 mb-2">
                    <div className="form-group">
                      <label className="form-label">Minimum phone number</label>
                      <div className="listinput_">
                        <input
                          type="number"
                          className="form-input"
                          placeholder="10"
                          value={settingForm.limitMinPhoneNumber}
                          name="limitMinPhoneNumber"
                          onChange={(e) => handleChange(e)}
                          onWheel={handleScroll}

                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 mb-2">
                    <div className="form-group">
                      <label className="form-label">Maximum phone number </label>
                      <input
                        type="number"
                        className="form-input"
                        placeholder="10"
                        value={settingForm.limitPhoneNumber}
                        name="limitPhoneNumber"
                        onChange={(e) => handleChange(e)}
                        onWheel={handleScroll}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 mb-2">
                    <div className="form-group">
                      <label className="form-label">Minimum Username</label>
                      <div className="listinput_">
                        <input
                          type="number"
                          className="form-input"
                          placeholder="10"
                          value={settingForm.limitMinUsername}
                          name="limitMinUsername"
                          onChange={(e) => handleChange(e)}
                          onWheel={handleScroll}

                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 mb-2">
                    <div className="form-group">
                      <label className="form-label">Maximum Username </label>
                      <input
                        type="number"
                        className="form-input"
                        placeholder="10"
                        value={settingForm.limitUsername}
                        name="limitUsername"
                        onChange={(e) => handleChange(e)}
                        onWheel={handleScroll}
                      />
                    </div>
                  </div>
                </div>

                <div className="blackList_">
                  <h3 className="subHeading_">Account Lockout Config</h3>
                  <div className="row">
                    <div className="col-md-6 mb-2">
                      <div className="form-group">
                        <label className="form-label">Maximum failed attempt</label>
                        <div className="listinput_">
                          <input
                            type="number"
                            className="form-input"
                            placeholder="10"
                            value={settingForm.maxFailedAttempt}
                            name="maxFailedAttempt"
                            onChange={(e) => handleChange(e)}
                            onWheel={handleScroll}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 mb-2">
                      <div className="form-group">
                        <label className="form-label">
                          {" "}
                          Lockout Period (second){" "}
                        </label>
                        <input
                          type="number"
                          className="form-input"
                          placeholder="10"
                          value={settingForm.lockoutPeriod}
                          name="lockoutPeriod"
                          onChange={(e) => handleChange(e)}
                          onWheel={handleScroll}
                        />
                      </div>
                    </div>
                    <div className="col-md-6 mb-2">
                      <div className="form-group">
                        <label className="form-label">Recovery Link Expire (minutes)</label>
                        <div className="listinput_">
                          <input
                            type="number"
                            className="form-input"
                            placeholder="10"
                            value={settingForm.recoveryLinkExpire}
                            name="recoveryLinkExpire"
                            onChange={(e) => handleChange(e)}
                            onWheel={handleScroll}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="blackList_">
                  <h3 className="subHeading_"> Twilio Config</h3>
                  <div className="row">
                    <div className="col-md-6 mb-2">
                      <div className="form-group">
                        <label className="form-label">Auth Token</label>
                        <div className="listinput_">
                          <input
                            type="text"
                            className="form-input"
                            placeholder=""
                            value={settingForm.authToken}
                            name="authToken"
                            onChange={(e) => handleChange(e)}
                            onWheel={handleScroll}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 mb-2">
                      <div className="form-group">
                        <label className="form-label">
                          {" "}
                          Service Id{" "}
                        </label>
                        <input
                          type="text"
                          className="form-input"
                          placeholder=""
                          value={settingForm.serviceId}
                          name="serviceId"
                          onChange={(e) => handleChange(e)}
                          onWheel={handleScroll}
                        />
                      </div>
                    </div>
                    <div className="col-md-6 mb-2">
                      <div className="form-group">
                        <label className="form-label">Account Sid</label>
                        <div className="listinput_">
                          <input
                            type="text"
                            className="form-input"
                            placeholder=""
                            value={settingForm.accountsid}
                            name="accountsid"
                            onChange={(e) => handleChange(e)}
                            onWheel={handleScroll}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="blackList_">
                  <h3 className="subHeading_"> Email Config</h3>
                  <div className="row">
                    <div className="col-md-6 mb-2">
                      <div className="form-group">
                        <label className="form-label">Send Grid</label>
                        <div className="listinput_">
                          <input
                            type="text"
                            className="form-input"
                            placeholder=""
                            value={settingForm.sgApiKey}
                            name="sgApiKey"
                            onChange={(e) => handleChange(e)}
                            onWheel={handleScroll}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 mt-2">
                  <button className="buttonCommon" type="submit">
                    Update
                  </button>
                </div>
              </form>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Setting;
