import React from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { logout } from "../../../redux/slices/auth";
import { useDispatch } from "react-redux";

const Header = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();


  // calling function for logout
  const handleLogout = () => {
    Swal.fire({
      title: "Do you want to logout?",
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: "Yes",
      customClass: {
        confirmButton: "sweet-alert-confirm-btn",
      },
      denyButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(
          logout({
            cb(res) {
                if(res.status === 200){
                  history.push("/login")
                }
            },
          })
        );
      }
    });
  };

  return (
    <>
      <nav className="main-header navbar navbar-expand navbar-white navbar-light">
        <ul className="navbar-nav">
          <li className="nav-item">
            <a
              className="nav-link"
              data-widget="pushmenu"
              href="#"
              role="button"
            >
              <i className="fas fa-bars"></i>
            </a>
          </li>

        </ul>

        <ul className="navbar-nav ml-auto">
          <li className="nav-item dropdown">
            <a className="nav-link" data-toggle="dropdown" href="#">
              <i className="far fa-user"></i>
            </a>
            <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
              <div className="dropdown-divider"></div>
              <a
                className="dropdown-item"
                onClick={() => {
                    handleLogout();
                }}
              >
                <i className="fas fa-sign-out-alt mr-2"></i> Logout
              </a>
            </div>
          </li>

          <li className="nav-item">
            <a
              className="nav-link"
              data-widget="fullscreen"
              href="#"
              role="button"
            >
              <i className="fas fa-expand-arrows-alt"></i>
            </a>
          </li>
        </ul>
      </nav>
    </>
  );
};

export default Header;
