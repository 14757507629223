import React from "react";
import { useHistory } from "react-router-dom";
import BrandLogo from "../../public/images/brand-logo.png";

const Sidebar = () => {
  const history = useHistory();
  const pathName = window.location.pathname;

  return (
    <div>
      <aside className="main-sidebar sideBar-bg sidebar-dark-primary elevation-4">
        <a
          onClick={() => {
            history.push("/");
          }}
          className="brand-link"
        >
          <img
            src={BrandLogo}
            className="brand-text font-weight-light"
            alt="Brand Logo"
            style={{ width: "74px" }}
          />
        </a>
        <div className="sidebar">
          <nav className="mt-2">
            <ul
              className="nav nav-pills nav-sidebar flex-column"
              data-widget="treeview"
              role="menu"
              data-accordion="true"
            >
              <li className="nav-item">
                <a
                  onClick={() => {
                    history.push("/");
                  }}
                  className={
                    ["/"].includes(pathName) ? "nav-link active" : "nav-link"
                  }
                >
                  <i className="nav-icon fas fa-th"></i>
                  <p>Dashboard</p>
                </a>
              </li>

              <li className="nav-item">
                <a
                  onClick={() => {
                    history.push("/logs");
                  }}
                  className={
                    ["/logs"].includes(pathName)
                      ? "nav-link active"
                      : "nav-link"
                  }
                >
                  <i className="nav-icon fas fa-list"></i>
                  <p>Logs</p>
                </a>
              </li>
              <li className="nav-item">
                <a
                  onClick={() => {
                    history.push("/usersList");
                  }}
                  className={
                    ["/usersList"].includes(pathName)
                      ? "nav-link active"
                      : "nav-link"
                  }
                >
                  <i className="nav-icon fas fa-user"></i>
                  <p>User List</p>
                </a>
              </li>


              <li className="nav-item">
                <a
                  onClick={() => {
                    history.push("/setting");
                  }}
                  className={
                    ["/setting"].includes(pathName)
                      ? "nav-link active"
                      : "nav-link"
                  }
                >
                  <i className="nav-icon fa fa-cog"></i>
                  <p>Settings</p>
                </a>
              </li>

              <li className="nav-item dropdown dropDownItems">
                <a
                  className={
                    ["/black-list"].includes(pathName)
                      ? "nav-link dropdown-toggle active"
                      : "nav-link dropdown-toggle"
                  }
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i className="fa fa-user-lock ms-1"></i>
                  <p className="ms-2">Black list</p>
                </a>
                <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
                  <li>
                    <a
                      className="dropdown-item"
                      onClick={() => {
                        history.push("/black-list?type=email");
                      }}
                    >
                      Email
                    </a>
                  </li>

                  <li>
                    <a
                      className="dropdown-item"
                      onClick={() => {
                        history.push("/black-list?type=username");
                      }}
                    >
                      Username
                    </a>
                  </li>

                  <li>
                    <a
                      className="dropdown-item"
                      onClick={() => {
                        history.push("/black-list?type=phone");
                      }}
                    >
                      Whatsapp Number
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </nav>
        </div>
      </aside>
    </div>
  );
};

export default Sidebar;
