const ApiPath = {
  AuthApiPath: {
    LOGIN_USER: "api/v1/users/admin-login",
    FORGOT_PASSWORD: "api/v1/users/forgot-password",
    RESET_PASSWORD: "api/v1/users/reset-password",
    LOGOUT_USER: "/api/v1/users/logout",
    LOGS_DETAILS: "/api/v1/logs",
    USERS_DETAILS: "/api/v1/users/dashboard",
    USERS_SETTING: "/api/v1/users/setting",
    UPDATE_USER_SETTING: "/api/v1/users/setting",
    BLACK_LIST: "/api/v1/blacklists",
    ADD_USER_BLACKLIST:"/api/v1/blacklists/create",
    USER_DELETE:"/api/v1/blacklists/delete",
    CHART_DATA:"/api/v1/blacklists/delete",
    USERS_DATA:"/api/v1/users"
  },
};

export default ApiPath;
