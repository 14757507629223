import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useWebSelector } from "../../../redux/selector/web";
import ReactPaginate from "react-paginate";
import { logsDetails, usersData } from "../../../redux/slices/web";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const UsersList = () => {
  document.title = "Home";
  const history = useHistory();
  const dispatch = useDispatch();
  const logsData = useWebSelector();
  const [getUsersData, setGetUsersData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState("");
  const [searchData, setSearchData] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedOption, setSelectedOption] = useState('');
  console.log("selectedOption", selectedOption);
  const [selectedDate, setSelectedDate] = useState(null);

  const formattedDate = moment(selectedDate).format('YYYY-MM-DD');

  console.log("selectDate", formattedDate)

  // get logDetails
  const getUsersDetails = (page) => {
    console.log("page", page)
    let params = {
      page: page ? page : currentPage,
      limit: 10,
      keyword: searchData,
    };
    if (selectedDate) {
      params = { ...params, date: formattedDate }
    }
    if (selectedOption) {
      params = { ...params, sign_up_by: selectedOption }
    }

    console.log("params", params)
    dispatch(
      usersData({
        ...params,
        cb(res) {
          console.log("res", res)
          if (res.status === 200) {
            setGetUsersData(res.data.data.data);
            setPageCount(res.data.data.total_pages);
          }
        },
      })
    );
  };


  // const handleDateChange = (date) => {
  //   const [start, end] = date;
  //   setStartDate(date);
  //   setEndDate(end);
  // }

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };


  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  // Page change handler
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected + 1);
    getUsersDetails(selected + 1);
  };

  useEffect(() => {
    getUsersDetails(1);
  }, [searchData, endDate, selectedDate, selectedOption]);

  return (
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0 headingMain">Users </h1>
            </div>
          </div>
        </div>
      </div>

      {/* Main content */}
      <section className="content">
        <div className="container-fluid">
          <div className="commonBox_">
            <div className="logmainBox_">

              <div className="flexBox mb-3">
                <div className="calenderIcon">
                  <label className="labelBox">Search : With Date </label>

                  {/* <img src="" alt="calender" /> */}
                  <i class="fa-solid fa-calendar-days calenderIconBox"></i>
                  <DatePicker
                    className='cateDate'
                    selected={selectedDate}
                    onChange={handleDateChange}
                    dateFormat="dd/MM/yyyy"
                    maxDate={new Date()}
                    // selected={startDate}
                    // onChange={(date)=>handleDateChange(date)}
                    // startDate={startDate}
                    // endDate={endDate}
                    // selectsRange
                    placeholderText="Date"
                  />
                </div>

                <div className="sigupSelection">
                  <label className="labelBox">Search : With Type </label>
                  <select value={selectedOption} onChange={handleOptionChange} className="cateDate">
                    <option value="">All</option>
                    <option value="ONECLICK">One Click</option>
                    <option value="EMAIL">Email</option>
                    <option value="PHONE">Whatsapp</option>
                    <option value="USERNAME">UseName</option>
                    <option value="GOOGLE">Google</option>
                    <option value="TELEGRAM">Telegram</option>
                  </select>
                </div>
                <div className="searchBox_">
                  <label className="labelBox">Search :</label>
                  <input
                    onChange={(e) => setSearchData(e.target.value)}
                    type="search"
                    className="cateDate"
                    placeholder="search..."
                    aria-controls="newsellerTable"
                  />
                </div>
              </div>
              <table className="table table-bordered tableMain_ ">
                <thead className="table-head">
                  <tr>
                    <th>Id</th>
                    <th>UserName</th>
                    <th>Email</th>
                    <th>Whatsapp Number</th>
                    <th>Sign Up With</th>
                    <th>Created At</th>
                  </tr>
                </thead>

                {logsData.loading ? (
                  <tbody>
                    <tr>
                      <td colSpan={9}>
                        <div className="loaderOuter d-flex justify-content-center ">
                          <div className="spinner-grow " role="status"></div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                ) : (
                  <>
                    {getUsersData && getUsersData.length > 0 ? (
                      <tbody>
                        {getUsersData?.map((data, index) => (
                          <>
                            <tr key={index}>
                              <td>{data.id}</td>
                              <td>{data.username ? data.username : "--"}</td>
                              <td>{data.email ? data.email : "--"}</td>
                              <td>{data.phone_number ? data.phone_code + data.phone_number : "--"}</td>
                              <td>{data.login_with ? data.login_with : "--"}</td>
                              <td>
                                {moment(data.createdAt).format(
                                  "DD-MM-YYYY hh:mm:ss"
                                )}
                              </td>
                            </tr>
                          </>
                        ))}
                      </tbody>
                    ) : (
                      <tbody>
                        <p className="p-2 m-0">No data found</p>
                      </tbody>
                    )}
                  </>
                )}
              </table>
              <div className="paginationMain_">
                {getUsersData && getUsersData.length > 0 && (
                  <ReactPaginate
                    previousLabel={"prev"}
                    nextLabel={"next"}
                    pageCount={pageCount}
                    pageRangeDisplayed={2}
                    marginPagesDisplayed={3}
                    onPageChange={handlePageChange}
                    containerClassName={"pagination"}
                    activeClassName={"active"}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default UsersList;
