import { all, call, put, takeLatest } from "redux-saga/effects";
import { ApiClient } from "../../../utilities/api";
import {
  onErrorStopLoad,
  setLogout,
  setUserLogin,
  setForgotPassword,
  setResetPassword,
} from "../../slices/auth";
import ApiPath from "../../../app/constants/apiPath";
import { toast } from "react-toastify";

// Worker saga will be fired on USER_FETCH_REQUESTED actions

function* resetPassword(action) {
  try {
    const resp = yield call(
      ApiClient.put,
      (action.url = ApiPath.AuthApiPath.RESET_PASSWORD),
      (action.payload = action.payload)
    );
    if (resp.status) {
      yield put(setResetPassword(resp.data.payload));
      yield call(action.payload.cb, resp);
      toast.success(resp.data.message);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    toast.dismiss();
    toast.error(e.response.data.message);
  }
}

function* forgotPassword(action) {
  try {
    const resp = yield call(
      ApiClient.post,
      (action.url = ApiPath.AuthApiPath.FORGOT_PASSWORD),
      (action.payload = action.payload)
    );
    if (resp.status) {
      yield put(setForgotPassword(resp.data.payload));
      yield call(action.payload.cb, resp);
      toast.success(resp.data.message);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    toast.dismiss();
    toast.error(e.response.data.message);
  }
}

function* userLogin(action) {
  try {
    const resp = yield call(
      ApiClient.post,
      (action.url = ApiPath.AuthApiPath.LOGIN_USER),
      (action.payload = action.payload)
    );
    if (resp.status) {
      localStorage.setItem(
        "authToken",
        resp.data.data.token ? resp.data.data.token : ""
      );
      yield put(setUserLogin(resp.data.payload));
      yield call(action.payload.cb, resp);
      // toast.success(resp.data.message);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    toast.dismiss();
    toast.error(e.response.data.message);
  }
}

function* userLogout(action) {
  try {
    const resp = yield call(
      ApiClient.get,
      (action.url = ApiPath.AuthApiPath.LOGOUT_USER),
      (action.payload = action.payload)
    );
    if (resp.status) {
      yield put(setLogout());
      yield call(action.payload.cb, resp);
      localStorage.removeItem("authToken");
      localStorage.removeItem("persist:root");
      // toast.success(resp.data.message);
    } else {
      throw resp;
    }
  } catch (e) {
    toast.error(e.response.data.message);
  }
}

function* authSaga() {
  yield all([
    takeLatest("auth/userLogin", userLogin),
    takeLatest("auth/logout", userLogout),
    takeLatest("auth/forgotPassword", forgotPassword),
    takeLatest("auth/resetPassword", resetPassword),
  ]);
}

export default authSaga;
