import { all, call, put, takeLatest } from "redux-saga/effects";
import { ApiClient } from "../../../utilities/api";
import {
  setLogsDetails,
  onErrorStopLoad,
  setUsersDeltail,
  setUpdateUserSettings,
  setGetBlackListData,
  setAddUsersBlacklist,
  setUsersData,
  setDeleteUser,
} from "../../slices/web";
import ApiPath from "../../../app/constants/apiPath";
import { toast } from "react-toastify";
const queryString = require('query-string');

// Worker saga will be fired on USER_FETCH_REQUESTED actions


function* deleteUser(action) {
  try {
    const resp = yield call(
      ApiClient.delete,
      (action.url = `${ApiPath.AuthApiPath.USER_DELETE}/${action.payload.id}`),
      (action.payload = action.payload)
    );
    if (resp.status) {
      yield put(setDeleteUser(resp.data.payload));
      yield call(action.payload.cb, resp);
      // toast.success(resp.data.message);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    toast.dismiss();
    toast.error(e.response.data.message);
  }
}

function* addUsersBlacklist(action) {
  try {
    const resp = yield call(
      ApiClient.post,
      (action.url = ApiPath.AuthApiPath.ADD_USER_BLACKLIST),
      (action.payload = action.payload)
    );
    if (resp.status) {
      yield put(setAddUsersBlacklist(resp.data.payload));
      yield call(action.payload.cb, resp);
      toast.success(resp.data.message);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    toast.dismiss();
    toast.error(e.response.data.message);
  }
}

function* getBlackListData(action) {
  let dataToSend;
  dataToSend = { ...action.payload };
  delete dataToSend.cb;
  try {
    const resp = yield call(
      ApiClient.get,
      (action.url = `${ApiPath.AuthApiPath.BLACK_LIST}?keyword=${dataToSend.keyword}&page=${dataToSend.page}&limit=${dataToSend.limit}&type=${dataToSend.type}`),
      (action.payload = action.payload)
    );
    if (resp.status) {
      yield put(setGetBlackListData(resp.data));
      yield call(action.payload.cb, resp);
      //   toast.success(resp.data.message);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    toast.error(e.response.data.message);
  }
}

function* usersDeltail(action) {
  try {
    const resp = yield call(
      ApiClient.get,
      (action.url = ApiPath.AuthApiPath.USERS_DETAILS),
      (action.payload = action.payload)
    );
    if (resp.status) {
      yield put(setUsersDeltail(resp.data));
      yield call(action.payload.cb, resp);
      //   toast.success(resp.data.message);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    // toast.error(e.response.data.message);
  }
}

function* logsDetails(action) {
  const dataToSend = { ...action.payload };
  delete dataToSend.cb;
  try {
    const resp = yield call(
      ApiClient.get,
      (action.url = `${ApiPath.AuthApiPath.LOGS_DETAILS}?keyword=${dataToSend.keyword}&page=${dataToSend.page}&limit=${dataToSend.limit}`),
      (action.payload = action.payload)
    );
    if (resp.status) {
      yield put(setLogsDetails(resp.data));
      yield call(action.payload.cb, resp);
      //   toast.success(resp.data.message);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    toast.error(e.response.data.message);
  }
}

function* usersData(action) {
  const dataToSend = { ...action.payload };
  
  delete dataToSend.cb;
  console.log("dataToSend", dataToSend);
  let query = queryString.stringify(dataToSend);
  try {
    const resp = yield call(
      ApiClient.get,
      (action.url = `${ApiPath.AuthApiPath.USERS_DATA}`+ '?' + query),

      // (action.url = `${ApiPath.AuthApiPath.USERS_DATA}?keyword=${dataToSend.keyword}&page=${dataToSend.page}&limit=${dataToSend.limit}&sign_up_by=${dataToSend.signUpBy}&date=${dataToSend.date}`),
      (action.payload = action.payload)
    );
    if (resp.status) {
      yield put(setUsersData(resp.data));
      yield call(action.payload.cb, resp);
      //   toast.success(resp.data.message);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    toast.error(e.response.data.message);
  }
}

function* usersSettings(action) {
  const dataToSend = { ...action.payload };
  delete dataToSend.cb;
  try {
    const resp = yield call(
      ApiClient.get,
      (action.url = ApiPath.AuthApiPath.USERS_SETTING),
      (action.payload = action.payload)
    );
    if (resp.status) {
      yield put(setLogsDetails(resp.data));
      yield call(action.payload.cb, resp);
      //   toast.success(resp.data.message);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    toast.error(e.response.data.message);
  }
}

function* updateUserSettings(action) {
  try {
    const resp = yield call(
      ApiClient.put,
      (action.url = ApiPath.AuthApiPath.UPDATE_USER_SETTING),
      (action.payload = action.payload)
    );
    if (resp.status) {
      yield put(setUpdateUserSettings(resp.data.payload));
      yield call(action.payload.cb, resp);
      toast.success(resp.data.message);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    toast.dismiss();
    toast.error(e.response.data.message);
  }
}

function* webSaga() {
  yield all([
    takeLatest("web/logsDetails", logsDetails),
    takeLatest("web/usersSettings", usersSettings),
  ]);
  yield all([takeLatest("web/usersDeltail", usersDeltail)]);
  yield all([takeLatest("web/updateUserSettings", updateUserSettings)]);
  yield all([takeLatest("web/getBlackListData", getBlackListData)]);
  yield all([takeLatest("web/addUsersBlacklist", addUsersBlacklist)]);
  yield all([takeLatest("web/deleteUser", deleteUser)]); 
  yield all([takeLatest("web/usersData", usersData)]); 
}

export default webSaga;
