import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  logsData: {},
  usersData: {},
};

export const webSlice = createSlice({
  name: "web",
  initialState,
  reducers: {
    deleteUser: (state) => {
      state.loading = true;
    },
    setDeleteUser: (state, action) => {
      state.loading = false;
    },
    addUsersBlacklist: (state) => {
      state.loading = true;
    },
    setAddUsersBlacklist: (state, action) => {
      state.loading = false;
    },
    getBlackListData: (state) => {
      state.loading = true;
    },
    setGetBlackListData: (state, action) => {
      state.loading = false;
    },

    updateUserSettings: (state) => {
      state.loading = true;
    },
    setUpdateUserSettings: (state, action) => {
      state.loading = false;
    },
    usersDeltail: (state) => {
      state.loading = true;
    },
    setUsersDeltail: (state, action) => {
      state.loading = false;
      state.usersData = action.payload;
    },

    logsDetails: (state) => {
      state.loading = true;
    },
    setLogsDetails: (state, action) => {
      state.loading = false;
      state.logsData = action.payload;
    },

    usersData: (state) => {
      state.loading = true;
    },
    setUsersData: (state, action) => {
      state.loading = false;
      state.logsData = action.payload;
    },

    usersSettings: (state) => {
      state.loading = true;
    },
    setUsersSettings: (state, action) => {
      state.loading = false;
      // state.logsData = action.payload;
    },
    onErrorStopLoad: (state) => {
      state.loading = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  logsDetails,
  setLogsDetails,
  onErrorStopLoad,
  usersData,
  setUsersData,
  usersDeltail,
  setUsersDeltail,
  usersSettings,
  setUsersSettings,
  updateUserSettings,
  setUpdateUserSettings,
  getBlackListData,
  setGetBlackListData,
  addUsersBlacklist,
  setAddUsersBlacklist,
  deleteUser,
  setDeleteUser,
} = webSlice.actions;

export default webSlice.reducer;
