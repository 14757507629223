import React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

const Footer = (props) => {
  const history = useHistory();
  const userLogout = () => {
    props.logout();
    history.push("/login");
  };

  return (
    <div>
      <footer className="main-footer text-center">
        <strong>
          ©2023{" "}
          <a className="footerlink_"
            onClick={() => {
              history.push("/");
            }}
          >
            10 Lotto Line
          </a>{" "}
        </strong>
        All rights reserved.
        <div className="float-right"></div>
      </footer>
    </div>
  );
};


export default Footer
