import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { addUsersBlacklist, onErrorStopLoad } from "../../../redux/slices/web";
import { useWebSelector } from "../../../redux/selector/web";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const AddUserBlacklist = (props) => {
  const { onCloseModal, type, getblackListDetails } = props;
  const blackListData = useWebSelector();
  const location = useLocation();
  const dispatch = useDispatch();
  const [value, setValue] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [phoneCode, setPhoneCode] = useState("");

  // call cancel function
  const handeCancelAddBlacklist = () => {
    onCloseModal();
  };

  // call api
  const handleSubmit = (e) => {
    e.preventDefault();

    if (!value && type === "email") {
      toast.error("Please enter email address");
      return;
    } else if (typeof value !== "undefined" && type === "email") {
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(value.trim())) {
        toast.error("Please enter valid email.");
        return;
      }
    } else if (!value && type === "username") {
      toast.error("Please enter username");
      return;
    } else if (!phoneNo && type === "phone") {
      toast.error("Please enter phone number");
      return;
    }

    let params = {
      type:
        type === "email" ? "email" : type === "username" ? "username" : "phone",
      value: type === "phone" ? "+" + phoneCode + phoneNo : value,
    };

    dispatch(
      addUsersBlacklist({
        ...params,
        cb(res) {
          if (res.status === 200) {
            onCloseModal();
            getblackListDetails();
          }
        },
      })
    );
  };

  // stop loader on page refresh
  useEffect(() => {
    dispatch(onErrorStopLoad());
  }, [dispatch]);

  // getting phone number value
  const onChangePhoneNumber = (value, data, event, formattedValue) => {
    let phoneCode = data.dialCode;
    let phoneNumber = value.slice(data.dialCode.length);
    setPhoneNo(phoneNumber);
    setPhoneCode(phoneCode);
  };

  return (
    <>
      <div>
        <form onSubmit={(e) => handleSubmit(e)}>
          <div className="addEmailOuter">
            {type === "phone" ? (
              <PhoneInput
                className="phoneInput"
                country="us"
                enableSearch={true}
                placeholder="Enter Phone number"
                onChange={(value, data, event, formattedValue) => {
                  onChangePhoneNumber(value, data, event, formattedValue);
                }}
              />
            ) : (
              <input
                onChange={(e) => setValue(e.target.value)}
                className="ps-2"
                type="text"
                placeholder={
                  type === "email"
                    ? "Enter email address"
                    : type === "username"
                    ? "Enter username"
                    : "Enter phone number"
                }
              />
            )}
          </div>
          <div className="modalBtns__">
            <button
              onClick={handeCancelAddBlacklist}
              type="button"
              className="cancelBtn"
            >
              Cancel
            </button>
            <button type="submit" className="saveBtn">
              {blackListData.loading && (
                <span className="spinner-border spinner-border-sm"></span>
              )}
              Save
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddUserBlacklist;
