import React, { useState, useRef } from "react";

const AuthLayout = (props) => {
  return (
    <div className="login-page authBg">
      <div className="login-box">
        <div className="card card-outline card-primary cardTopOutline cardBg">
          {props.children}
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
