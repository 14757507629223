import { Switch, Route } from "react-router-dom";

import * as Layouts from "./../app/layouts";
import * as Containers from "./../app/containers";

function Router() {
  return (
    <Switch>
      {/* Auth Routes*/}
      <Layouts.NonPrivateRoute
        exact
        path="/login"
        component={Containers.Login}
      />
      {/* <Layouts.NonPrivateRoute
        exact
        path="/forgotPassword"
        component={Containers.ForgotPassword}
      />
      <Layouts.NonPrivateRoute
        exact
        path="/resetPassword/:resetPasswordToken"
        component={Containers.ResetPassword}
      /> */}
      <Layouts.PrivateRoute exact path="/" component={Containers.Dashboard} />
      <Layouts.PrivateRoute
        exact
        path="/setting"
        component={Containers.Setting}
      />
        <Layouts.PrivateRoute
        exact
        path="/black-list"
        component={Containers.Blacklist}
      />
      <Layouts.PrivateRoute exact path="/logs" component={Containers.Logs} />
      <Layouts.PrivateRoute exact path="/usersList" component={Containers.UsersList} />
      <Route component={Containers.NotFound} />
    </Switch>
  );
}

export default Router;
