import React from 'react';
import { Route } from 'react-router-dom';

import Authenticator from './authenticator';
import AuthLayout from '../components/shared/AuthLayout';

const NonPrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={props => (
    <Authenticator isAuthenticationRequired={false} {...props}>
      <AuthLayout>
        <Component {...props} />
      </AuthLayout>
    </Authenticator>
  )} />
)

export default NonPrivateRoute;