import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useWebSelector } from "../../../redux/selector/web";
import ReactPaginate from "react-paginate";
import { logsDetails } from "../../../redux/slices/web";
import moment from "moment";

const Logs = () => {
  document.title = "Home";
  const history = useHistory();
  const dispatch = useDispatch();
  const logsData = useWebSelector();
  const [getLogsData, setGetLogsData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState("");
  const [searchData, setSearchData] = useState("");

  // get logDetails
  const getlogDetails = (page) => {
    let params = {
      page: page ? page : currentPage ,
      limit: 10,
      keyword: searchData,
    };
    dispatch(
      logsDetails({
        ...params,
        cb(res) {
          if (res.status === 200) {
            setGetLogsData(res.data.data.list);
            setPageCount(res.data.data.total_pages);
          }
        },
      })
    );
  };

  // Page change handler
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected + 1);
    getlogDetails(selected + 1);
  };

  useEffect(() => {
    getlogDetails(1);
  }, [searchData]);

  return (
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0 headingMain">Logs</h1>
            </div>
          </div>
        </div>
      </div>

      {/* Main content */}
      <section className="content">
        <div className="container-fluid">
          <div className="commonBox_">
            <div className="logmainBox_">
              <div className="searchBox_">
                <label>Search :</label>
                <input
                  onChange={(e) => setSearchData(e.target.value)}
                  type="search"
                  className="form-control form-control-sm"
                  placeholder="search..."
                  aria-controls="newsellerTable"
                />
              </div>
              <table className="table table-bordered tableMain_ ">
                <thead className="table-head">
                  <tr>
                    <th>Id</th>
                    <th>Event</th>
                    <th>Title</th>
                    <th>Ip Address</th>
                    <th>Created At</th>
                  </tr>
                </thead>

                {logsData.loading ? (
                  <tbody>
                    <tr>
                      <td colSpan={9}>
                        <div className="loaderOuter d-flex justify-content-center ">
                          <div className="spinner-grow " role="status"></div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                ) : (
                  <>
                    {getLogsData && getLogsData.length > 0 ? (
                      <tbody>
                        {getLogsData?.map((data, index) => (
                          <>
                            <tr key={index}>
                              <td>{data.id}</td>
                              <td>{data.event}</td>
                              <td>{data.title}</td>
                              <td>{data.ip_address}</td>
                              <td>
                                {moment(data.createdAt).format(
                                  "DD-MM-YYYY hh:mm:ss"
                                )}
                              </td>
                            </tr>
                          </>
                        ))}
                      </tbody>
                    ) : (
                      <tbody>
                        <p className="p-2 m-0">No data found</p>
                      </tbody>
                    )}
                  </>
                )}
              </table>
              <div className="paginationMain_">
                {getLogsData && getLogsData.length > 0 && (
                  <ReactPaginate
                    previousLabel={"prev"}
                    nextLabel={"next"}
                    pageCount={pageCount}
                    pageRangeDisplayed={2}
                    marginPagesDisplayed={3}
                    onPageChange={handlePageChange}
                    containerClassName={"pagination"}
                    activeClassName={"active"}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Logs;
