import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import {
  usersDeltail,
  getChartData,
  onErrorStopLoad,
} from "../../../redux/slices/web";
import LoginChart from "../charts/loginChart";

const Dashboard = (props) => {
  document.title = "Dashboard";
  const history = useHistory();
  const dispatch = useDispatch();
  const [dashboardData, setDashboardData] = useState([]);

  // getting users deltails
  useEffect(() => {
    dispatch(
      usersDeltail({
        cb(res) {
          if (res.status === 200) {
            setDashboardData(res.data.data);
          }
        },
      })
    );
  }, []);

  // chart options
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
      },
    },
  };

  // chart data
  const authAnalysis = {
    labels: dashboardData?.login_barchart_data?.map(
      (data, index) => data?.label
    ),
    datasets: [
      {
        fill: true,
        label: "login",
        data: dashboardData?.login_barchart_data?.map(
          (data, index) => data?.value
        ),
        borderColor: "#275AFF",
        backgroundColor: "#380d59cc",
      },
      {
        fill: true,
        label: "registration",
        data: dashboardData?.registed_barchart_data?.map(
          (data, index) => data?.value
        ),
        borderColor: "#FB466C",
        backgroundColor: "#9c54c1d9",
      },
    ],
  };

  // stop loader when page is refreshing
  useEffect(() => {
    dispatch(onErrorStopLoad());
  }, [dispatch]);

  return (
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0 headingMain">Dashboard</h1>
            </div>
          </div>
        </div>
      </div>

      {/* Main content */}

      <section className="content commonBox_ me-3 ms-3">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-3 col-6">
              <div className="small-box dashboxcolor">
                <div className="inner">
                  <h3>{dashboardData.total_users}</h3>
                  <p>Users</p>
                </div>
                <div className="icon">
                  <i className="ion ion-person-add" />
                </div>
                <a
                  // onClick={() => {
                  //   history.push("/user");
                  // }}
                  className="small-box-footer"
                >
                  <i className="fas " />
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-6">
              <div className="small-box dashboxcolor_two">
                <div className="inner">
                  <h3>{dashboardData.emails}</h3>
                  <p>Emails</p>
                </div>
                <div className="icon">
                  <i className="ion ion-heart" />
                </div>
                <a className="small-box-footer">
                  <i className="fas " />
                </a>
              </div>
            </div>

            <div className="col-lg-3 col-6">
              <div className="small-box bg-warning">
                <div className="inner">
                  <h3>{dashboardData.sent_sms}</h3>
                  <p>Sent SMS</p>
                </div>
                <div className="icon">
                  <i className="fa fa-sms" />
                </div>
                <a className="small-box-footer">
                  <i className="fas " />
                </a>
              </div>
            </div>

            <div className="col-lg-3 col-6">
              <div className="small-box bg-danger">
                <div className="inner">
                  <h3>{dashboardData.voice_calls}</h3>
                  <p>Voice Calls</p>
                </div>
                <div className="icon">
                  <i className="fa fa-phone" />
                </div>
                <a className="small-box-footer">
                  <i className="fas " />
                </a>
              </div>
            </div>
          </div>
        </div>

        <LoginChart
          classes="mt-5 mb-2"
          header="Services Sales"
          options={options}
          data={authAnalysis}
          chartType="Bar"
        />
      </section>
    </div>
  );
};

export default Dashboard;
