import React, { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { userLogin, onErrorStopLoad } from "../../../redux/slices/auth";
import { useDispatch } from "react-redux";
import { useAuthSelector } from "../../../redux/selector/auth";

const Login = (props) => {
  document.title = "Admin Login";
  const history = useHistory();
  const dispatch = useDispatch();
  const authData = useAuthSelector();
  const [errorMessages, setErrorMessages] = useState({});
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  // Getting values of input field
  const handleChange = (e) => {
    const { value, name } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrorMessages({ ...errorMessages, [name]: "" });
  };

  // validation of input fields
  const validateForm = () => {
    let errors = {};
    let formIsValid = true;
    if (!formData.email) {
      formIsValid = false;
      errors = { ...errors, email: "Please enter your email." };
    } else if (typeof formData.email !== "undefined") {
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(formData.email.trim())) {
        formIsValid = false;
        errors = { ...errors, email: "Please enter valid email." };
      }
    }

    if (!formData.password) {
      formIsValid = false;
      errors = { ...errors, password: "Please enter your password." };
    }

    setErrorMessages(errors);
    return formIsValid;
  };

  // Form submit handler
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      let params = {
        email: formData.email.trim(),
        password: formData.password,
      };
      dispatch(
        userLogin({
          ...params,
          cb(ress) {
            if (ress.status === 200) {
              history.push("/");
            }
          },
        })
      );
    }
  };

  // Stop loader on page refresh
  useEffect(() => {
    dispatch(onErrorStopLoad());
  }, [dispatch]);

  return (
    <>
      <div className="loginPage">
        <div className="card-header text-center">
          <h3 className="mb-0">
            <b>Admin</b> Login
          </h3>
        </div>
        <div className="card-body login-card-body">
          <form
            onSubmit={(e) => {
              handleSubmit(e);
            }}
          >
            <label for="username" class="form-label d-block">
              Email{" "}
            </label>
            <div className="input-group mb-3 ">
              <input
                type="text"
                className="form-control form-input"
                name="email"
                placeholder="Email Address"
                value={formData.email}
                onChange={(e) => {
                  handleChange(e);
                }}
              />

              <span className="error invalid-feedback">
                {errorMessages.email}
              </span>
            </div>
            <label for="username" class="form-label d-block">
              Password
            </label>
            <div className="input-group mb-1">
              <input
                type="password"
                className="form-control form-input"
                name="password"
                placeholder="Password"
                value={formData.password}
                onChange={(e) => {
                  handleChange(e);
                }}
              />

              <span className="error invalid-feedback">
                {errorMessages.password}
              </span>
            </div>

            <div className="row">
              {/* <div className="col-12 text-end">
              <a className="forgotLink"
                onClick={() => {
                  history.push("/forgotPassword");
                }}
              >
                Forgot password?
              </a>
            </div> */}

              <div className="col-12 text-center">
                <button
                  className="loginBtnCommon btnYellow mt-3 mw-100"
                  disabled={authData.loading}
                  type="submit"
                >
                  {authData.loading && (
                    <span className="spinner-border spinner-border-sm"></span>
                  )}
                  &nbsp;&nbsp;
                  <span>Sign In</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Login;
