import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  getBlackListData,
  onErrorStopLoad,
  deleteUser,
} from "../../../redux/slices/web";
import ReactPaginate from "react-paginate";
import moment from "moment";
import CustomModal from "../../components/shared/CustomModal";
import AddUserBlacklist from "./addUserBlacklist";
import { useWebSelector } from "../../../redux/selector/web";
import swal from "sweetalert";

const Blacklist = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const tableData = useWebSelector();
  const blackListType = new URLSearchParams(location.search).get("type");

  const [searchData, setSearchData] = useState("");
  const [blackListDetails, setBlackListDetails] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState("");
  const [key, setKey] = useState(Math.random());
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
    type: "",
  });

  // Page change handler
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected + 1);
    getblackListDetails(selected + 1);
  };

  // get logDetails
  const getblackListDetails = (page = currentPage) => {
    let params = {
      type:
        blackListType === "email"
          ? "email"
          : blackListType === "username"
            ? "username"
            : "phone",
      page: page,
      limit: 10,
      keyword: searchData,
    };
    dispatch(
      getBlackListData({
        ...params,
        cb(res) {
          if (res.status === 200) {
            setBlackListDetails(res.data.data.list);
            setPageCount(res.data.data.total_pages);
          }
        },
      })
    );
  };

  useEffect(() => {
    getblackListDetails();
  }, [searchData, blackListType]);

  // open modal dialog
  const handleOpenModal = (id) => {
    setModalDetail({
      show: true,
      flag: "email",
      title:
        blackListType === "email"
          ? "Add via email"
          : blackListType === "username"
            ? "Add via username"
            : "Add via phone number",
      type: "email",
    });
    setKey(Math.random());
  };

  //closeModal
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };

  useEffect(() => {
    dispatch(onErrorStopLoad());
  }, [dispatch]);

  // remove user from blacklist
  const handleUserDelete = (_id) => {
    swal({
      className: "sweetAlertDelete",
      title: "Are you sure?",
      text: "Are you sure to remove this user ?",
      icon: "warning",
      dangerMode: true,
      buttons: true,
    }).then((willDelete) => {
      if (willDelete) {
        let params = {
          id: _id,
        };
        dispatch(
          deleteUser({
            ...params,
            cb(res) {
              if (res.status === 200) {
                getblackListDetails();
              }
            },
          })
        );
        swal("Deleted!", "User has been removed!", "success");
      }
    });
  };

  return (
    <>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0 headingMain">Black list</h1>
              </div>
            </div>
          </div>

          {/* Main content */}
          <section className="content">
            <div className="container-fluid">
              <div className="commonBox_">
                <div className="logmainBox_">
                  {blackListType === "email" ? (
                    <>
                      <div className="row align-items-end mb-3">
                        <div className="col-lg-6 col-md-6">
                          <button
                            onClick={() => handleOpenModal()}
                            className="addMoreBtn"
                          >
                            <i className="fa fa-plus me-2"></i>
                            Add Email
                          </button>
                        </div>
                        <div className="searchBox_N col-lg-6 col-md-6">
                          <div className="searchLableBox">
                            <label>Search :</label>
                            <input
                              onChange={(e) => setSearchData(e.target.value)}
                              type="search"
                              className="form-control form-control-md"
                              placeholder="search..."
                              aria-controls="newsellerTable"
                            />
                          </div>
                        </div>
                      </div>

                      <table className="table table-bordered tableMain_ ">
                        <thead className="table-head">
                          <tr>
                            <th>Id</th>
                            <th>Email</th>
                            <th>Created At</th>
                            <th>Action</th>
                          </tr>
                        </thead>

                        {tableData.loading ? (
                          <tbody>
                            <tr>
                              <td colSpan={9}>
                                <div className="loaderOuter d-flex justify-content-center ">
                                  <div
                                    className="spinner-grow "
                                    role="status"
                                  ></div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        ) : (
                          <>
                            {blackListDetails && blackListDetails.length > 0 ? (
                              <tbody>
                                {blackListDetails.map((item, index) => (
                                  <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{item.value}</td>
                                    <td>
                                      {moment(item.createdAt).format(
                                        "DD-MM-YYYY hh:mm:ss"
                                      )}
                                    </td>

                                    <td>
                                      <i
                                        onClick={() =>
                                          handleUserDelete(item.id)
                                        }
                                        className="fa fa-trash text-danger text-align-center trashBtn"
                                      ></i>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            ) : (
                              <tbody>
                                <p className="p-2 m-0">No data found</p>
                              </tbody>
                            )}
                          </>
                        )}
                      </table>
                    </>
                  ) : blackListType === "username" ? (
                    <>
                      <div className="row align-items-end mb-3">
                        <div className="col-lg-6 col-md-6">
                          <button
                            onClick={() => handleOpenModal()}
                            className="addMoreBtn"
                          >
                            <i className="fa fa-plus me-2"></i>
                            Add Username
                          </button>
                        </div>
                        <div className="searchBox_N col-lg-6 col-md-6">
                          <div className="searchLableBox">
                            <label>Search :</label>
                            <input
                              onChange={(e) => setSearchData(e.target.value)}
                              type="search"
                              className="form-control form-control-md"
                              placeholder="search..."
                              aria-controls="newsellerTable"
                            />
                          </div>
                        </div>
                      </div>

                      <table className="table table-bordered tableMain_ ">
                        <thead className="table-head">
                          <tr>
                            <th>Id</th>
                            <th>Username</th>
                            <th>Created At</th>
                            <th>Action</th>
                          </tr>
                        </thead>

                        {tableData.loading ? (
                          <tbody>
                            <tr>
                              <td colSpan={9}>
                                <div className="loaderOuter d-flex justify-content-center ">
                                  <div
                                    className="spinner-grow "
                                    role="status"
                                  ></div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        ) : (
                          <>
                            {blackListDetails && blackListDetails.length > 0 ? (
                              <tbody>
                                {blackListDetails.map((item, index) => (
                                  <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{item.value}</td>
                                    <td>
                                      {moment(item.createdAt).format(
                                        "DD-MM-YYYY hh:mm:ss"
                                      )}
                                    </td>

                                    <td>
                                      <i
                                        onClick={() =>
                                          handleUserDelete(item.id)
                                        }
                                        className="fa fa-trash text-danger text-align-center trashBtn"
                                      ></i>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            ) : (
                              <tbody>
                                <p className="p-2 m-0">No data found</p>
                              </tbody>
                            )}
                          </>
                        )}
                      </table>
                    </>
                  ) : (
                    <>
                      <div className="row align-items-end mb-3">
                        <div className="col-lg-6 col-md-6">
                          <button
                            onClick={() => handleOpenModal()}
                            className="addMoreBtn"
                          >
                            <i className="fa fa-plus me-2"></i>
                            Add Whatsapp No.
                          </button>
                        </div>
                        <div className="searchBox_N col-lg-6 col-md-6">
                          <div className="searchLableBox">
                            <label>Search :</label>
                            <input
                              onChange={(e) => setSearchData(e.target.value)}
                              type="search"
                              className="form-control form-control-md"
                              placeholder="search..."
                              aria-controls="newsellerTable"
                            />
                          </div>
                        </div>
                      </div>

                      <table className="table table-bordered tableMain_ ">
                        <thead className="table-head">
                          <tr>
                            <th>Id</th>
                            <th>Whatsapp</th>
                            <th>Created At</th>
                            <th>Action</th>
                          </tr>
                        </thead>

                        {tableData.loading ? (
                          <tbody>
                            <tr>
                              <td colSpan={9}>
                                <div className="loaderOuter d-flex justify-content-center ">
                                  <div
                                    className="spinner-grow "
                                    role="status"
                                  ></div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        ) : (
                          <>
                            {blackListDetails && blackListDetails.length > 0 ? (
                              <tbody>
                                {blackListDetails.map((item, index) => (
                                  <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{item.value}</td>
                                    <td>
                                      {moment(item.createdAt).format(
                                        "DD-MM-YYYY hh:mm:ss"
                                      )}
                                    </td>

                                    <td>
                                      <i
                                        onClick={() =>
                                          handleUserDelete(item.id)
                                        }
                                        className="fa fa-trash text-danger text-align-center trashBtn"
                                      ></i>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            ) : (
                              <tbody>
                                <p className="p-2 m-0">No data found</p>
                              </tbody>
                            )}
                          </>
                        )}
                      </table>
                    </>
                  )}
                </div>
                <div className="paginationMain_">
                  {blackListDetails && blackListDetails.length > 0 && (
                    <ReactPaginate
                      previousLabel={"prev"}
                      nextLabel={"next"}
                      pageCount={pageCount}
                      pageRangeDisplayed={2}
                      marginPagesDisplayed={3}
                      onPageChange={handlePageChange}
                      containerClassName={"pagination"}
                      activeClassName={"active"}
                    />
                  )}
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>

      <CustomModal
        key={key}
        showModal={modalDetail.show}
        setShowModal={setModalDetail}
        title={modalDetail.title}
        type={modalDetail.type}
        dialogClassName="modal-dialog-centered"
        backdrop="static"
        ids={modalDetail.flag === "email" ? "addemailModal" : ""}
        child={
          modalDetail.flag === "email" ? (
            <AddUserBlacklist
              onCloseModal={() => handleOnCloseModal()}
              type={
                blackListType === "email"
                  ? "email"
                  : blackListType === "username"
                    ? "username"
                    : "phone"
              }
              getblackListDetails={getblackListDetails}
            />
          ) : (
            ""
          )
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </>
  );
};

export default Blacklist;
