import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

const Authenticator = (props) => {
  const authToken = localStorage.getItem("authToken");
  const history = useHistory();

  const authenticateUser = (isAuthenticationRequired) => {
    if (authToken) {
      // history.push("/");
    } else if (!authToken && isAuthenticationRequired) {
      history.push("/login");
    }
  };

  useEffect(() => {
    authenticateUser(props.isAuthenticationRequired);
  }, []);

  return <>{props.children}</>;
};

export default Authenticator;
